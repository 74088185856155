body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-4jnixx-MuiStack-root {
  padding: 0 !important;
}

.custom-profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.custom-profile::after {
  content:  '';
  width: 120px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px dashed #dee2e6;
  border-radius: 50%;
  transform: translate(-50%,-50%);
}

.custom-profile > span {
  font-size: xx-large;
  font-weight: 500;
}

.custom-profile-image {
  width: 200px;
  height: 100px;
  background-color: #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.custom-profile-image::after {
  content:  '';
  width: 240px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px dashed #dee2e6;
  transform: translate(-50%,-50%);
}

.custom-profile-image > span {
  font-size: xx-large;
  font-weight: 500;
}